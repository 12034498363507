<template>
  <div class="wrapper">
    <div>
      <div :class="`relative ${classNoQuery} ${iphoneView() && 'iphone'}`">
        <ImageLazy
          id="HeroBanner"
          class="HeroBanner absolute"
          :src="'/images/membership-sk/bg-header.webp'"
          alt="HeroBanner"
          width="100%"
          height="100%"
        />
        <ImageLazy
          id="HeroBannerMobile"
          class="HeroBannerMobile absolute"
          :src="'/images/membership-sk/bg-header-mobile.webp'"
          alt="HeroBannerMobile"
          width="100%"
          height="100%"
        />
      </div>
      <div :class="`hero ${classNoQuery} ${iphoneView() && 'iphone'}`">
        <ImageLazy
          :src="'/images/membership-sk/ill_s&k.svg'"
          width="115px"
          class="sk-image"
          alt="SKIllustration"
        />
        <div class="subtitle-header">
          Siap <span>Crazy Rich</span> dan <span>Memburu Parcel Poin?</span>
          <br />
          Intip cara jitunya di bawah ini!
        </div>
      </div>
      <div class="wrapper__content">
        <Content class="content-padding" />
        <div class="divide-line-space" />
        <OtherInfo class="content-padding" />
        <div class="divide-line-space" />
        <div class="wrapper__content__faq">
          <ExpandCard
            :id="'faq'"
            :use-description="false"
            :url="'lionparcel://app/faqcategory?category_id=36'"
            :title="'Masih Bingung? Yuk! Cek FAQ '"
            :icon-url="'/images/membership-sk/question.svg'"
        />
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import ImageLazy from "@/components/ImageLazy/index.vue";
import Content from "./component/Content.vue";
import OtherInfo from "./component/OtherInfo.vue";
import ExpandCard from "../components/ExpandCard/ExpandCard.vue";

export default {
  name: "MembershipSK",
  components: {
    ImageLazy,
    Content,
    OtherInfo,
    ExpandCard,
  },
  data() {
    return {
      iosPlatforms: ["iPhone", "iPad", "iPod"],
      classHasQueryDefault: "",
      listHideIdentifier: [
        "#main-nav",
        ".new-main-footer",
        ".end-footer",
        "#wa-cs",
      ],
      classNoQuery: "",
    };
  },
  mounted() {
    // if link has no ?default= query, will hide uniqueID on listHideIdentifier
    if (
      !this.$route.query.default &&
      this.isMobile() &&
      window.innerWidth < 991
    ) {
      this.classNoQuery = "no-query-default";
      for (const uniqName of this.listHideIdentifier) {
        const el = document.querySelector(uniqName);
        if (el) {
          el.style.display = "none";
        }
      }
    }
  },
  methods: {
    isMobile() {
      if (this.isSsr()) {
        return false;
      }
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    iphoneView() {
      if (this.isSsr()) {
        return false;
      }
      const navigator = window.navigator;
      const platform = navigator.userAgentData?.platform || window.navigator.platform;

      return this.iosPlatforms.indexOf(platform) !== -1;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/style.scss";

$height-banner-desktop: 377px;
$height-banner-mobile: 270px;

.wrapper {
  @media (max-width: 500px) {
    .relative {
      height: 216px;

      &.iphone {
        height: 236px;
      }
    }

    .hero {
      margin-top: 92px;
    }
  }

  @media (max-width: 350px) {
    width: 360px !important;
  }

  &__content {
    &__faq {
      padding: 20px 20px 65px 20px;

      @include bigScreen() {
        display: none;
      }
    }
  }
}

.content-padding {
  @include bigScreen() {
    padding: 0 96px;
  }
}

.HeroBannerMobile {
  display: none;

  @include smallScreen() {
    display: block;
  }
}

.HeroBanner {
  display: block;

  @include smallScreen() {
    display: none;
  }
}

.sk-image {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.hero {
  margin-top: 188.5px;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;

  &.no-query-default {
    @media (max-width: 374px) {
      margin-top: 64px;
    }

    @media (min-width: 375px) {
      margin-top: 64px;
    }

    @include tabletScreen() {
      margin-top: 75px;
    }
  }

  @media (max-width: 350px) {
    width: 360px;
    margin: auto;
  }

  @include tabletScreen() {
    margin-top: 105.5px;
  }

  @media (min-width: 1022px) and (max-width: 1450px) {
    margin-top: 140.5px;
  }
}

.hero.iphone {
  margin-top: 90px !important;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;

  &.no-query-default {
    @media (min-width: 375px) {
      margin-top: 65px !important;
    }
  }

  @media (min-width: 390px) and (max-width: 500px) {
    margin-top: 110px !important;
  }
}

.wrapper-tab {
  padding: 0 20px;
  display: flex;
  column-gap: 12px;
  justify-content: space-between;
}

.content-tab {
  padding: 0 20px;
  margin-bottom: 18px;
}

.relative {
  position: relative;
  width: 100%;
  height: $height-banner-mobile;
  margin-bottom: 43px;

  @include bigScreen() {
    margin-top: $top-banner-desktop;
    height: $height-banner-desktop;
  }

  @media (max-width: 1178px) {
    margin-top: 3.25rem;
  }

  @media (min-width: 1022px) and (max-width: 1450px) {
    height: 317px;
  }

  &.no-query-default {
    height: 268px !important;

    @media (max-width: 1240px) {
      margin-top: -36px;
    }

    @media (max-width: 374px) {
      margin-top: -49px;
    }
  }

  @include for-size(xmobile) {
    height: $height-banner-mobile;
    margin-bottom: 7px;
  }

  &.iphone {
    height: 290px;
  }
}

.absolute {
  position: absolute;
  z-index: -1;
  bottom: 0;
  width: 100%;
}

.title-header {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: $color-white;
}

.subtitle-header {
  color: $color-white;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  text-align: center;

  @include smallScreen() {
    font-size: 14px;
    line-height: 21px;
  }

  span {
    font-weight: 700;
  }
}

.divide-line-space {
  width: 100%;
  height: 8px;
  background: $color-gray-pensive;

  @include bigScreen() {
    display: none;
  }
}
</style>
