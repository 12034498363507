<template>
  <div class="table-wrapper">
    <div class="w-full">
      <div class="thead">
        <div
          v-for="(header, index) in headers"
          :key="index"
          class="th"
        >
          <span>{{ header }}</span>
        </div>
      </div>
      <div class="tbody">
        <div class="rows">
          <div
            v-for="(data, index) in dataItems"
            :key="index"
            class="tr"
          >
            <div
              v-for="(val, idx) in data"
              :key="idx"
              :class="['td', { 'justify-start has-img': val.img }]"
            >
              <div v-if="val.img">
                <ImageLazy
                  :src="val.img"
                  style="display: flex;margin-right: 4px;"
                  alt=""
                />
              </div>
              <span>{{ val.value }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageLazy from "@/components/ImageLazy/index.vue";

export default {
  name: "SKTable",
  components: {
    ImageLazy,
  },
  props: {
    headers: {
      type: Array,
      require: true,
      default: () => [],
    },
    dataItems: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/style.scss";

.table-wrapper {
  overflow-x: hidden;
}

.w-full {
  width: 100%;
  table-layout: auto;
}

.thead {
  display: flex;
  width: 100%;
  height: 100%;

  .th {
    background: linear-gradient(180deg, $color-red-8 0%, $color-base 100%);
    padding: 10px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;

    &:nth-child(1) {
      border-top-left-radius: 16px;
      max-width: 125px;

      @include bigScreen() {
        max-width: 159px;
      }
    }

    &:last-child {
      border-top-right-radius: 16px;
      margin-right: 0;
    }

    span {
      color: $color-white;
      font-weight: 600;
      font-size: 10px;
      line-height: 15px;

      @include bigScreen() {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
}

.tbody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    margin-top: 6px;

    &:nth-child(odd) {
      .td {
        background: #ffe9ed;
      }
    }

    &:last-child {
      .td {
          &:nth-child(1) {
          border-bottom-left-radius: 16px;
            }

          &:last-child {
            border-bottom-right-radius: 16px;
          }
        }
    }

    .td {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-top: 10px;

      @media (min-width: 376px) {
        padding-left: 8px;
      }

      &.justify-start {
        justify-content: flex-start;
      }

      &:nth-child(1) {
        max-width: 125px;

        @include bigScreen() {
          max-width: 159px;
        }
      }

      &:not(:last-child) {
        margin-right: 6px;
      }

      span {
        font-weight: 600;
        font-size: 10px;
        line-height: 0;

        @include bigScreen() {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
}
</style>
