<template>
  <div class="other-info">
    <h1>Info Lainnya</h1>
    <div class="other-info__tabbing">
      <ButtonTab
        v-for="item in otherInfoTabs"
        :id="item.id"
        :key="item.id + item.isActive"
        :text="item.tab"
        :is-active="item.isActive"
        @click="(e) => onClickTable(e, item.id)"
      />
    </div>
    <div v-if="windowWidth <= 1024" class="other-info__expand-mobile">
      <ExpandCard
        v-for="({ title, data, type, slotName }, index) in dataTabs"
        :id="String(index)"
        :key="title + index"
        :title="title"
        :use-description="true"
      >
        <template #[index]>
          <FAQ
            :data="data"
            :type="type"
            :slot-name="String(slotName)"
          />
        </template>
      </ExpandCard>
    </div>

    <div v-else class="other-info__expand">
      <div class="other-info__expand__item">
        <ExpandCard
          v-for="({ title, data, type, slotName }, index) in dataTabChunk(
            'left'
          )"
          :id="String(index)"
          :key="title + index"
          :title="title"
          :use-description="true"
        >
          <template #[index]>
            <FAQ :data="data" :type="type" :slot-name="String(slotName)" />
          </template>
        </ExpandCard>
      </div>
      <div class="other-info__expand__item">
        <ExpandCard
          v-for="({ title, data, type, slotName }, index) in dataTabChunk(
            'right'
          )"
          :id="String(index + countIndex)"
          :key="title + (index + countIndex)"
          :title="title"
          :use-description="true"
        >
          <template #[index+countIndex]>
            <FAQ :data="data" :type="type" :slot-name="String(slotName)" />
          </template>
        </ExpandCard>
        <ExpandCard
          :id="'faq-desktop'"
          :use-description="false"
          :url="'lionparcel://app/faqcategory?category_id=36'"
          :title="'Masih Bingung? Yuk! Cek FAQ '"
          :icon-url="'/images/membership-sk/question.svg'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonTab from "./ButtonTab.vue";
import ExpandCard from "../../components/ExpandCard/ExpandCard.vue";
import FAQ from "./FAQ.vue";
import { otherInfoTabs, faqDataTabs } from "../../app/document";
import mixinMobile from "@/misc/mixinMobile";

export default {
  components: {
    ButtonTab,
    ExpandCard,
    FAQ,
  },
  mixins: [mixinMobile],
  data() {
    return {
      otherInfoTabs,
      faqDataTabs,
      dataTabs: [],
      countIndex: 0,
    }
  },
  created() {
    this.dataTabs = this.faqDataTabs[0].content;
  },
  methods: {
    onClickTable(event, id) {
      event.target.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center"
      });
      this.faqDataTabs.forEach((item, index) => {
        if (item.id === id) {
          this.otherInfoTabs[index].handler(true);
          this.dataTabs = item.content;
        } else {
          this.otherInfoTabs[index].handler(false);
        }
      });
    },
    dataTabChunk(position) {
      if (this.dataTabs.length === 1) {
          if (position === "left") {
              return this.dataTabs;
          }
          return []
      }
      const chunk = Math.floor(this.dataTabs.length / 2);
      if (position === "left") {
        return this.dataTabs.slice(0, chunk);
      }
      this.countIndex = chunk;
      return this.dataTabs.slice(chunk);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/style.scss";

.other-info {

  h1 {
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    margin: 18px 20px;

    @include bigScreen() {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      margin: 0;
    }
  }

  &__tabbing {
    margin-bottom: 32px;
    padding: 0 20px;
    display: -webkit-box;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: auto;
    overflow: auto;
    flex-wrap: nowrap;

    @include bigScreen() {
      margin: 23px 0;
      padding: 0;
    }

    > div {
      margin-right: 12px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__expand-mobile {
    display: none;

    @include smallScreen() {
      display: block;
    }

    padding: 0 20px;

    > div {
      margin-bottom: 12px;
    }
  }

  &__expand {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-bottom: 95px;
    &__item {
      display: flex;
      width: 50%;
      flex-direction: column;
      > div {
        margin-bottom: 1rem;
      }
    }
    &__item:first-of-type {
      padding-right: 2rem;
    }
    @include smallScreen() {
      display: none;
    }
  }
}
</style>
