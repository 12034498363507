export const parcelPointInfo = "Parcel Poin adalah program loyalitas yang ditujukan kepada pelanggan Lion Parcel yang bertransaksi di agen, aplikasi dan marketplace. Parcel Poin dapat ditukarkan dengan beragam penawaran menarik yang dapat dilihat di aplikasi Lion Parcel."
export const parcelPointBenefits = "Semakin sering bertransaksi, semakin tinggi level membershipmu dan semakin banyak Parcel Poin yang bisa kamu dapatkan"
export const otherInfoTabs = [
  {
    id: 'HowToGetParcelPoin',
    tab: 'Mendapatkan Parcel Poin',
    isActive: true,
    handler(value) { this.isActive = value }
  },
  {
    id: 'HowToMainTainParcelPoin',
    tab: 'Mempertahankan Level',
    isActive: false,
    handler(value) { this.isActive = value }
  },
  {
    id: 'HowToCalculateParcelPoin',
    tab: 'Perhitungan Parcel Poin',
    isActive: false,
    handler(value) { this.isActive = value }
  },
  {
    id: 'UseFullyTheParcelPoin',
    tab: 'Kegunaan Parcel Poin',
    isActive: false,
    handler(value) { this.isActive = value }
  },
  {
    id: 'HowToExchangeParcelPoin',
    tab: 'Penukaran Parcel Poin',
    isActive: false,
    handler(value) { this.isActive = value }
  },
  {
    id: 'EtCeteraParcelPoin',
    tab: 'Lain-Lain',
    isActive: false,
    handler(value) { this.isActive = value }
  }
]

export const faqDataTabs = [
  {
    id: 'HowToGetParcelPoin',
    content: [
      {
        title: 'Bagaimana Cara Mendapatkan Parcel Poin?',
        data: [
          {
            value: "Jika bertransaksi di Aplikasi, otomatis akan mendapatkan Parcel Poin.",
          },
          {
            value:
              "Jika bertransaksi di Agen Terdekat, maka nomor handphone pengirim harus sama dengan nomor handphone yang terdaftar di Aplikasi akan mendapatkan Parcel Poin.",
          },
          {
            value:
              "Jika bertransaksi di Marketplace, maka nomor handphone seller & penerima yang terdaftar di Aplikasi akan mendapatkan Parcel Poin",
          },
        ],
        type: "list",
      },
      {
        title: "Jenis Transaksi Apa yang Dihitung untuk Mendapatkan Parcel Poin?",
        data: [
          { value: 'Pick Up (Regular & Favorit)' },
          { value: 'Drop Paket (Regular, Favorit & Indomaret)' },
          { value: 'COD (Hanya ongkos pengiriman)' },
          { value: 'BisaBelanja (Hanya ongkos pengiriman)' },
          { value: 'Datang langsung ke Agen Terdekat' },
          { value: 'Marketplace (Tokopedia & Bukalapak).' },
        ],
        type: "list",
      },
      {
        title: "Kapan Parcel Poin Didapatkan?",
        data: [
          {
            value:
              "Parcel Poin akan didapatkan & masuk ke akun kamu setelah paket sudah diterima oleh penerima.",
          },
        ],
        type: "list",
      },
      {
        title: "Apakah Parcel Poin Dapat Kadaluarsa?",
        data: [
          {
            value:
              "Parcel Poin akan berlaku sampai tanggal 31 Desember 2024 & akan menjadi 0 pada tanggal 1 Januari 2025.",
          },
        ],
        type: "list",
      },
    ]
  },
  {
    id: 'HowToMainTainParcelPoin',
    content: [
      {
        title: "Bagaimana Cara Mempertahankan Level Membership?",
        data: [
          {
            value:
              "Kamu harus mempertahankan total transaksimu sesuai tabel leveling diatas, setiap kuartal atau setiap periode.",
          },
          {
            value: `Periode Perhitungan:`,
            child: [
              "Periode 1:<span style='font-weight: 600;'> 1 Januari - 31 Maret</span>. ",
              "Periode 2:<span style='font-weight: 600;'> 1 April - 30 Juni</span>.",
              "Periode 3:<span style='font-weight: 600;'> 1 Juli - 31 September</span>.",
              "Periode 4:<span style='font-weight: 600;'> 1 Oktober - 31 Desember</span>.",
            ],
          },
          {
            value: `Simulasi:
            <br/>
            Aulia pada bulan Mei berada di level Crazy Rich. Lalu pada tanggal 1 Juli - 31 September Aulia masih di level Crazy Rich dan tidak melakukan transaksi sama sekali, maka pada tanggal 1 Oktober dia akan turun level menjadi Warga.
            `
          }
        ],
        type: "list",
        slotName: 1,
      },
    ]
  },
  {
    id: 'HowToCalculateParcelPoin',
    content: [
      {
        title: "Bagaimana Perhitungan Parcel Poin?",
        data: [
          {
            value:
              "Setiap transaksi dengan <span style='font-weight: 600;'>kelipatan Rp500 </span>akan mendapatkan 1 Parcel Poin & akan digandakan sesuai levelnya",
          },
          {
            value: `Parcel Poin akan dihitung berdasarkan total transaksi yang kamu bayarkan (tidak termasuk biaya diluar pengiriman/ongkir).`,
          },
        ],
        type: "list",
        slotName: 1,
      },
      {
        title: "Bagaimana Simulasi Perhitungan  Parcel Poin?",
        data: [
          {
            value:
              `<span style='font-weight: 600;'>Transaksi di Aplikasi</span>
              <br />
              <br />
              Aulia pada bulan Februari berada di level <span style='font-weight: 600;'>Jutawan</span>. Pada 9 Februari, Aulia melakukan transaksi pengiriman <span style='font-weight: 600;'>Pick Up di Aplikasi</span> sebesar Rp10.000.
              <br />
              <br />
              Kemudian tanggal 10 Februari, paket telah sampai di alamat tujuan & diterima oleh penerima. Maka Parcel Poin yang akan didapat Aulia adalah:
              <br />
              <br />
              <span style='font-weight: 600;'>Parcel Poin = (10.000 /500) * 4 = 80 Parcel Poin</span>
              `,
          },
          {
            value: `<span style='font-weight: 600;'>Transaksi di Agen Offline</span>
            <br />
            <br />
            Aulia pada bulan Februari berada di level <span style='font-weight: 600;'>Jutawan</span>. Pada 9 Februari, Aulia melakukan pengiriman di <span style='font-weight: 600;'>Agen Terdekat</span> sebesar Rp10.000.
            <br />
            <br />
            Kemudian tanggal 10 Februari, paket telah sampai di alamat tujuan & diterima oleh penerima. Dengan catatan nomor Handphone Aulia telah terdaftar di Aplikasi sama dengan nomor handphone pengirim, maka Parcel Poin yang akan didapat adalah:
            <br />
            <br />
            <span style='font-weight: 600;'>Parcel Poin = 10.000 /500 * 2 = 40 Parcel Poin</span>
            `,
            
          },
        ],
        type: "list",
        slotName: 1,
      },
    ]
  },
  {
    id: 'UseFullyTheParcelPoin',
    content: [
      {
        title: "Apa Kegunaan dari Parcel Poin?",
        data: [
          {
            value:
              "Kamu dapat menukarkan Parcel Poinmu sesuai dengan harga e-voucher yang tersedia di katalog <span style='font-weight: 600;'> (selama persediaan voucher masih ada)</span>.",
          },
          {
            value: "Kamu juga bisa menggunakan Parcel Poin sebagai pembayaranmu saat mengirim paket atau bertransaksi."
          }
        ],
        type: "list",
      },
    ]
  },
  {
    id: 'HowToExchangeParcelPoin',
    content: [
      {
        title: "Bagaimana Cara Menukarkan Parcel Poin dengan Voucher?",
        data: `Tukarkan Parcel Poin dengan voucher, caranya:
          </br>
          <ol id="ol-style">
            <li> Pastikan kamu <span style='font-weight: 600;'> memiliki Parcel Poin </span> yang aktif </li>
            <li> Buka <span style='font-weight: 600;'> aplikasi </span> dan klik <span style='font-weight: 600;'> Tukarkan Parcel Poinmu </span> pada beranda atau halaman level membershipmu </li>
            <li> Pilih <span style='font-weight: 600;'>  kategori </span> atau <span style='font-weight: 600;'> brand </span> yang kamu inginkan </li>
            <li> Pilih voucher yang kamu mau dan klik <span style='font-weight: 600;'> See voucher </span> </li>
            <li> Klik <span style='font-weight: 600;'> Buy </span> untuk menambahkan ke keranjang </li>
            <li> Jika sudah masuk ke keranjang, Klik <span style='font-weight: 600;'> Checkout </span> </li> 
            <li> Muncul confirmation order, jika sudah sesuai klik  <span style='font-weight: 600;'> Pay </span> </li>
            <li> Klik <span style='font-weight: 600;'> Tukarkan </span> dan <span style='font-weight: 600;'> Selesai</span>, voucher sudah bisa dinikmati </li> 
            <li> Voucher akan <span style='font-weight: 600;'> aktif maksimal 2x24 jam </span> sejak penukaran Parcel Poinmu </li>
            <li> Voucher bisa <span style='font-weight: 600;'> langsung digunakan </span> untuk pembayaran di kasir. Kecuali nominal vouchermu melebihi total pembayaran </li> 
          </ol>
        `,
        type: "text",
      },
      {
        title: "Bagaimana Cara Menukarkan Parcel Poin untuk Transaksi?",
        data: `Tukarkan Parcel Poin dengan transaksi di Aplikasi, caranya: 
          </br>
          <ol id="ol-style">
            <li> Pastikan kamu <span style='font-weight: 600;'> memiliki Parcel Poin </span> yang aktif </li>
            <li> Buka aplikasi dan pilih menu <span style='font-weight: 600;'> Pick Up, Drop Paket, atau BisaBelanja </span> </li>
            <li> Lakukan transaksi </li>
            <li> Klik <span style='font-weight: 600;'> Bayar </span></li>
            <li> Di menu pembayaran, <span style='font-weight: 600;'> aktifkan Parcel Poin </span> </li>
            <li> Pilih <span style='font-weight: 600;'> Metode Pembayaran </span> dan selesaikan pembayaranmu </li>
            <li> Selesai </li>
          </ol>
        `,
        type: "text"
      },
      {
        title: "Apa Syarat & Ketentuan Voucher?",
        data: [
          {
            value:
                "Parcel Poin didapatkan <span style='font-weight: 600;'> setelah paketmu sampai di penerima </span>",
          },
          {
            value: "Batas penukaran voucher setiap pengguna adalah: <span style='font-weight: 600;'>Maksimal 1x penukaran voucher atau maksimal total transaksi Rp1.000.000 dalam 24 jam</span>. Jika salah satu sudah terpenuhi (baik yang 1x ataupun total transaksinya) maka kamu harus menunggu waktu 24 jam dari penukaran voucher pertama"
          },
          {
            value: "Voucher mempunyai <span style='font-weight: 600;'>syarat ketentuan & masa berlaku masing-masing</span>, kamu bisa cek di dalam vouchernya",
          },
          {
            value: "Voucher yang sudah <span style='font-weight: 600;'> habis masa berlaku </span> maka akan dianggap <span style='font-weight: 600;'>hangus</span>",
          },
          {
            value: "Voucher <span style='font-weight: 600;'>tidak dapat diuangkan</span> atau <span style='font-weight: 600;'> dikembalikan </span> ke brand tersebut"
          },
          {
            value: "Lion Parcel <span style='font-weight: 600;'> tidak bertanggung jawab terhadap segala bentuk kerugian </span> yang dialami oleh Pengguna akibat kelalaian dalam menggunakan atau <span style='font-weight: 600;'> menjaga kerahasiaan </span> kode voucher"
          },
          {
            value: "Program Voucher ini dikelola oleh <span style='font-weight: 600;'> Ultra Voucher </span> sehingga Lion Parcel tidak bertanggung jawab penuh diatasnya."
          },
          {
            value: "Penukaran e-voucher tersedia dalam jumlah yang terbatas <span style='font-weight: 600;'> (selama persediaan voucher masih ada) </span>"
          },
          {
            value: "Apabila masih mengalami kendala, silakan hubungi <span style='font-weight: 600;'> CS Ultra Voucher </span> dengan <span style='font-weight: 600;'> memberikan nomor order </span> di halaman riwayat Parcel Poin:",
            child: [
                "Email: <span style='font-weight: 600;'> info@ultravoucher.co.id </span>",
                "Nomor Telepon: <span style='font-weight: 600;'> +62 811 9276 700 </span> atau <span style='font-weight: 600;'> 021 - 22831000 </span>"
            ]
          }
        ],
        type: "list",
        slotName: 1,
      },
      {
        title: "Bagaimana Jika Sudah Menukar Parcel Poin Tapi Voucher Belum Masuk? ",
        data: [
          {
            value: "Pastikan kamu telah memuat ulang halaman <span style='font-weight: 600;'> My Voucher </span>"
          },
          {
            value: "Apabila masih mengalami kendala, silakan hubungi <span style='font-weight: 600;'> CS Ultra Voucher </span> dengan <span style='font-weight: 600;'> memberikan nomor order </span> di halaman riwayat Parcel Poin:",
            child: [
              "Email: <span style='font-weight: 600;'> info@ultravoucher.co.id </span>",
              "Nomor Telepon: <span style='font-weight: 600;'> +62 811 9276 700 </span> atau <span style='font-weight: 600;'> 021 - 22831000 </span>"
            ]
          }
        ],
        type: "list",
        slotName: 1,
      }
    ]
  },
  {
    id: 'EtCeteraParcelPoin',
    content: [
      {
        title: "Saya Sudah Pernah Transaksi di Luar Aplikasi, Apakah Saya Mendapatkan Parcel Poin?",
        data: [
          {
            value:
              "Untuk pengguna baru aplikasi Lion Parcel, semua transaksi di luar aplikasi akan kami berikan parcel poin sesuai dari total transaksi di 30 hari terakhir.",
          },
        ],
        type: "list",
      },
      {
        title: "Bagaimana Jika Mengubah Nomor Handphone?",
        data: [
          {
            value:
              "Parcel Poin kamu akan ditransfer ke nomor handphone yang baru & dihitung berdasarkan transaksi di nomor handphone yang baru",
          },
        ],
        type: "list",
      },
      {
        title: "Bagaimana Jika Menghapus Akun Lion Parcel?",
        data: [
          {
            value:
              "Parcel Poin kamu akan menjadi 0.",
          },
          {
            value:
              "Jika kamu mendaftarkan akun baru dengan menggunakan nomor yang pernah dihapus, maka Parcel Poin akan menjadi 0 & diperhitungkan kembali dari awal.",
          },
        ],
        type: "list",
      },
      {
        title: "Bagaimana Jika Terjadi Kecurangan & Penipuan?",
        data: [
          {
            value:
              "Lion Parcel berhak untuk melakukan penyesuain poin (penambahan, pembatalan, pengurangan) jika terjadi penyalahgunaan, kecurangan dan aktifitas mencurigakan lainnya.",
          },
        ],
        type: "list",
      },
    ]
  },
]

export const headerMembership = ["Level", "Total Transaksi Selesai"];

export const headerParcelPoin = [
  "Nama Level",
  "Poin Di Aplikasi & Marketplace",
  "Poin Di Agen Terdekat",
];

export const dataItemMembership = [
  [
    {
      img: '/images/membership-sk/avatar1.svg',
      value: "Warga",
    },
    {
      value: "Rp0",
    },
  ],
  [
    {
      img: '/images/membership-sk/avatar2.svg',
      value: "Jutawan",
    },
    {
      value: "Rp50.000",
    },
  ],
  [
    {
      img: '/images/membership-sk/avatar3.svg',
      value: "Miliarder",
    },
    {
      value: "Rp500.000",
    },
  ],
  [
    {
      img: '/images/membership-sk/avatar4.svg',
      value: "Konglomerat",
    },
    {
      value: "Rp5.000.000",
    },
  ],
  [
    {
      img: '/images/membership-sk/avatar5.svg',
      value: "Crazy Rich",
    },
    {
      value: ">= Rp50.000.000",
    },
  ],
];

export const dataItemParcelPoinBosspack = [
  [
    {
      img: '/images/membership-sk/avatar1.svg',
      value: "Warga",
    },
    {
      value: "4x Lebih Banyak",
    },
    {
      value: "2x Lebih Banyak",
    },
  ],
  [
    {
      img: '/images/membership-sk/avatar2.svg',
      value: "Jutawan",
    },
    {
      value: "8x Lebih Banyak",
    },
    {
      value: "2x Lebih Banyak",
    },
  ],
  [
    {
      img: '/images/membership-sk/avatar3.svg',
      value: "Miliarder",
    },
    {
      value: "16x Lebih Banyak",
    },
    {
      value: "2x Lebih Banyak",
    },
  ],
  [
    {
      img: '/images/membership-sk/avatar4.svg',
      value: "Konglomerat",
    },
    {
      value: "30x Lebih Banyak",
    },
    {
      value: "2x Lebih Banyak",
    },
  ],
  [
    {
      img: '/images/membership-sk/avatar5.svg',
      value: "Crazy Rich",
    },
    {
      value: "60x Lebih Banyak",
    },
    {
      value: "2x Lebih Banyak",
    },
  ],
];

export const dataItemParcelPoinNonBosspack = [
  [
    {
      img: '/images/membership-sk/avatar1.svg',
      value: "Warga",
    },
    {
      value: "2x Lebih Banyak",
    },
    {
      value: "1x Lebih Banyak",
    },
  ],
  [
    {
      img: '/images/membership-sk/avatar2.svg',
      value: "Jutawan",
    },
    {
      value: "4x Lebih Banyak",
    },
    {
      value: "1x Lebih Banyak",
    },
  ],
  [
    {
      img: '/images/membership-sk/avatar3.svg',
      value: "Miliarder",
    },
    {
      value: "8x Lebih Banyak",
    },
    {
      value: "1x Lebih Banyak",
    },
  ],
  [
    {
      img: '/images/membership-sk/avatar4.svg',
      value: "Konglomerat",
    },
    {
      value: "15x Lebih Banyak",
    },
    {
      value: "1x Lebih Banyak",
    },
  ],
  [
    {
      img: '/images/membership-sk/avatar5.svg',
      value: "Crazy Rich",
    },
    {
      value: "30x Lebih Banyak",
    },
    {
      value: "1x Lebih Banyak",
    },
  ],
];
