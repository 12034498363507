<template>
  <div class="content-membership">
    <h1 class="text-title">Apa itu Parcel Poin?</h1>
    <p class="text-description text-description__top">
      {{ parcelPointInfo }}
    </p>
    <div class="content-membership__table">
      <div class="content-membership__leveling">
        <h1 class="text-title text-description__top">Cari tau tentang leveling</h1>
        <SKTable
          class="text-description__top"
          :headers="headerMembership"
          :data-items="dataItemMembership"
        />
      </div>
    <div class="content-membership__table-2">
      <div class="content-membership__leveling">
        <h1 class="text-title text-description__top" id="t-and-c-bosspack">Keuntungan Level & Perhitungannya</h1>
        <SKTable
          class="text-description__top"
          :headers="headerParcelPoin"
          :data-items="dataItemParcelPoinBosspack"
        />
      </div>
      <div class="content-membership__leveling">
        <h1 class="text-title text-description__top" id="t-and-c-non-bosspack">Keuntungan Level & Perhitungannya</h1>
        <SKTable
          class="text-description__top"
          :headers="headerParcelPoin"
          :data-items="dataItemParcelPoinNonBosspack"
        />
      </div>
    </div>
    </div>
    <p class="text-description">
      {{ parcelPointBenefits }}
    </p>
  </div>
</template>

<script>
import SKTable from "./SKTable.vue";
import {
  parcelPointInfo,
  parcelPointBenefits,
  headerMembership,
  dataItemMembership,
  headerParcelPoin,
  dataItemParcelPoinBosspack,
  dataItemParcelPoinNonBosspack,
} from "../../app/document";

export default {
  components: { SKTable },
  data() {
    return {
      headerMembership,
      dataItemMembership,
      headerParcelPoin,
      dataItemParcelPoinBosspack,
      dataItemParcelPoinNonBosspack,
      parcelPointInfo,
      parcelPointBenefits,
    };
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
